/**
 * Direttiva che costruisce il player o i botoni per avviare il player degli oggetti Scorm
 */
import {User, ItemFrontEndWrapper, SenecaResponse, ScormRegistration, TopicMessageContainer} from "atfcore-commonclasses";

export interface IScormPlayerDirectiveScope extends ng.IScope {
    playerVisibile: boolean,
    launchEnabled: boolean,
    requireSingletonCheck: boolean,
    firstSingletonCheckMade: boolean,
    singletonLock: boolean,
    startLabel: string,
    cannotStartLabel: string,
    scormId: string,
    scormUrl: string,
    itemWrapper: ItemFrontEndWrapper,
    attributes: any,
    createScormRegistration: Function,
    getScormLaunchUrl: Function,
    markSingletonInUse: Function,
    isSingletonInUse: Function,
    launchScorm: Function,
    reloadPage: Function,
    reloadState: Function,
    adminMode: boolean,
    getScormPreviewUrl: Function
    checkEnableCertificaion: Function;
}
angular.module('app').directive("scormPlayer", ($window, $state, BgacademyApplicationData, GlobalApplicationData, ItemService, $translate, toaster, $interval, $sessionStorage) => {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            playerVisibile: '=',
            itemWrapper: '=',
            checkEnableCertificaion: '=',
            attributes: '=',
            reloadPage: '='
        },
        link: link,
        templateUrl: 'app/shared/scormPlayer/scormPlayer.html'
    };
    function link($scope: IScormPlayerDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
        $scope.$on('scormConsumed', (event: any, data: any) => {
            console.log("event: " + event);
        });
        $scope.$on('message', (event: any, data: any) => {
            console.log("event: " + event);
        });

        // Sto in ascolto di quando l'evento scorm finisce
        window.addEventListener('message', function (event) {
            if (event.data == BgacademyApplicationData.constants.SCORM_REFRESH_PAGE_DONE) {
                // Se richiesto, ricarico la pagina
                if (<string>(typeof $scope.reloadPage) != "null" && typeof $scope.reloadPage != "undefined") {
                    $scope.reloadPage();
                }
            }
            if (event.data == BgacademyApplicationData.constants.SCORM_CONSUMED && <string>(typeof $scope.checkEnableCertificaion) != "null" && <string>(typeof $scope.checkEnableCertificaion) != "undefined") {
                $scope.checkEnableCertificaion();
            }
        }, false);

        // Verifica se sono nella parte amministrativa
        $scope.adminMode = $sessionStorage.adminMode;

        $scope.launchEnabled = false;
        $scope.requireSingletonCheck = false;
        $scope.firstSingletonCheckMade = false;
        $scope.singletonLock = true;

        $scope.startLabel = $translate.instant("itemDetail.START_SCORM_OBJECT");
        $scope.cannotStartLabel = $translate.instant("itemDetail.CANNOT_START_SCORM_OBJECT");

        $scope.createScormRegistration = function () {
            let promise = ItemService.createScormRegistration.update({
                scormId: $scope.scormId,
                scormAttributes: $scope.attributes,
                itemId: $scope.itemWrapper && $scope.itemWrapper.item && $scope.itemWrapper.item.itemId
            });
            return promise.$promise;
        };

        $scope.getScormLaunchUrl = function () {
            let promise = ItemService.getScormLaunchUrl.query({
                registrationId: $scope.itemWrapper.scormRegistration.registrationId,
                checkForSingleton: $scope.requireSingletonCheck
            });
            return promise.$promise;
        }

        // Preview amministratore
        $scope.getScormPreviewUrl = function () {
            let promise = ItemService.getScormPreviewUrl.query({
                scormId: $scope.scormId
            });
            return promise.$promise;
        }

        /* Script per il caricamento della pagina
        $scope.reloadPage = () => {
            // Se richiesto, ricarico la pagina
            if (typeof $scope.reloadState != "null" && typeof $scope.reloadState != "undefined") {
                $scope.reloadState();
            } else {
                $window.location.reload();
            }
        }*/

        $scope.isSingletonInUse = function () {
            ItemService.isSingletonInUse.query({}).$promise
                .then((data: SenecaResponse<boolean>) => {
                    if (data && data.error) {
                        // Se va male, assumo che l'oggetto sia bloccato
                        $scope.singletonLock = true;
                    } else {
                        // Predo lo stato che mi è arrivato
                        $scope.singletonLock = !!data.response;
                    }
                    // Mi segno che ho fatto la richiesta al server
                    $scope.firstSingletonCheckMade = true;
                })
                .catch((error: any) => {
                    // Se va male, assumo che l'oggetto sia bloccato
                    $scope.singletonLock = true;
                });
        }

        $scope.markSingletonInUse = function () {
            ItemService.markSingletonInUse.update({});
        }

        $scope.launchScorm = function () {
            // Se sono amministratore, lo lancio in modalità preview
            if ($scope.adminMode) {
                $scope.getScormPreviewUrl()
                    .then((data: SenecaResponse<string>) => {
                        if (data && (data.error || !data.response)) {
                            // Dati non validi, quindi alzo l'errore
                            toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                        } else {
                            // Salvo l'url, cosa che aprirà l'iFrame che a sua volta avvierà l'oggetto
                            $scope.scormUrl = data.response;
                        }
                    })
                    .catch((error: any) => {
                        let errors: Array<any> = [];
                        errors.push({ severity: "danger", message: $translate.instant("error.generic.UNKNOWN_ERROR") });
                        $scope.$root.$emit("showApplicationModalErrors", errors);
                    });
            } else if ($scope.itemWrapper.scormRegistration) {
                // Se ho già una registrazione, apro direttamente l'oggetto
                $scope.getScormLaunchUrl()
                    .then((data: SenecaResponse<string>) => {
                        if (data && (data.error || !data.response)) {
                            // Dati non validi, quindi alzo l'errore
                            toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                        } else {
                            // Segnalo l'inizio della fruizione
                            $scope.$emit("ItemStarted", true);

                            // Salvo l'url, cosa che aprirà l'iFrame che a sua volta avvierà l'oggetto
                            $scope.scormUrl = data.response;
                            // E quindi marco anche il fatto che l'oggetto è in uso (periodicamente ogni 2 secondi dato che la registrazione ne dura 3) 
                            if ($scope.requireSingletonCheck) {
                                $scope.markSingletonInUse();
                                let stopMark = $interval(function () {
                                    $scope.markSingletonInUse();
                                }, 2000);
                                $scope.$on("$destroy", function () {
                                    $interval.cancel(stopMark);
                                });
                            }
                        }
                    })
                    .catch((error: any) => {
                        let errors: Array<any> = [];
                        errors.push({ severity: "danger", message: $translate.instant("error.generic.UNKNOWN_ERROR") });
                        $scope.$root.$emit("showApplicationModalErrors", errors);
                    });
            }
            else {
                // Se non ce l'ho, allora verifico se l'oggetto è di consumo libero, nel qual caso la creo
                if ($scope.itemWrapper.item.itemType == "SCORM_FREE") {
                    $scope.createScormRegistration()
                        .then((data: SenecaResponse<ScormRegistration>) => {
                            if (data && (data.error || !data.response)) {
                                // Dati non validi, quindi alzo l'errore
                                toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                            } else {
                                // Salvo la registration
                                $scope.itemWrapper.scormRegistration = data.response;
                                // E richiamo questo stesso metodo per finire nell'altro ramo dell'if
                                $scope.launchScorm();
                            }
                        })
                        .catch((error: any) => {
                            let errors: Array<any> = [];
                            errors.push({ severity: "danger", message: $translate.instant("error.generic.UNKNOWN_ERROR") });
                            $scope.$root.$emit("showApplicationModalErrors", errors);
                        });
                }
                else {
                    // Errore, qui non ci dovrei nemmeno arrivare
                    toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                }
            }
        }

        if ($scope.playerVisibile) {
            // Cerco l'id dell'oggetto esterno
            // Vedo anche se questo oggetto ha l'atributo sigleton (solo un singleton alla volta può essere lanciato dallo stesso utente)
            if ($scope.itemWrapper && $scope.itemWrapper.item && $scope.itemWrapper.item.itemAttributes) {
                $scope.itemWrapper.item.itemAttributes.forEach(attr => {
                    if (attr.attributeType == "EXTERNAL_OBJECT_ID") {
                        $scope.scormId = attr.attributeValue;
                    }
                    if (attr.attributeType == "EXTERNAL_OBJECT_SINGLETON" && attr.attributeValue == "true" && !$scope.adminMode) {
                        $scope.requireSingletonCheck = true;
                    }
                });
            }
            // Vedo se posso abilitare l'utente a lanciare l'oggetto o meno
            if (($scope.itemWrapper && $scope.itemWrapper.scormRegistration) || ($scope.itemWrapper && $scope.itemWrapper.item && $scope.itemWrapper.item.itemType == "SCORM_FREE") || $scope.adminMode) {
                $scope.launchEnabled = true;
                // Se l'oggetto non richiede la verifica per i singleton, allora spengo il lock,
                // altrimenti lancio il listener per vedere se ci sono altri singleton in esecuzioe
                if ($scope.requireSingletonCheck) {
                    $scope.isSingletonInUse();
                    let stopCheck = $interval(function () {
                        $scope.isSingletonInUse();
                    }, 2000);
                    $scope.$on("$destroy", function () {
                        $interval.cancel(stopCheck);
                    });
                }
                else {
                    $scope.singletonLock = false;
                }
            }
        }
    }
});